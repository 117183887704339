<template>
  <div class="login">
    <div class="login-box">
      <div class="login-title">账户登录</div>
      <div class="content">
        <el-form
          :model="ruleForm"
          status-icon
          :rules="rules"
          ref="ruleForm"
          label-width="0px"
          class="demo-ruleForm"
        >
          <el-form-item prop="username">
            <el-input
              v-model="ruleForm.username"
              autocomplete="off"
              placeholder="请输入用户名"
            >
              <template slot="prepend">
                <i class="el-icon-user"></i>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="username">
            <el-input
              type="password"
              v-model="ruleForm.password"
              autocomplete="off"
              placeholder="请输入密码"
              show-password
            >
              <template slot="prepend">
                <i class="el-icon-key"></i>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              class="login-btn"
              type="primary"
              @click="submitForm('ruleForm')"
              >登陆</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import menus from "@/data/menus.js";
export default {
  data() {
    return {
      ruleForm: {
        username: "",
        password: "",
      },
      rules: {
        username: [
          { required: true, message: "用户名不能为空！", trigger: "blur" },
        ],
        password: [
          { required: true, message: "密码不能为空！", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios
            .post("login", this.ruleForm)
            .then((result) => {
              this.$message.success("欢迎回来，" + result.data.username);
              setTimeout(() => {
                this.$store.commit("login", result.data);
                if (this.$route.name == null) {
                  // 跳转到首页
                  this.$router.push({ path: menus[0].path });
                }
              }, 1000);
            })
            .catch((err) => {});
        }
      });
    },
  },
};
</script>

<style lang="less">
.login {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  &-box {
    position: absolute;
    width: 300px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 30px;
    padding-top: 0;
    border-radius: 6px;
    box-shadow: 6px 4px 11px 1px #ccc;
  }
  &-title {
    font-size: 25px;
    text-align: center;
    line-height: 100px;
  }
  &-btn {
    width: 100%;
  }
}
</style>