import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    hasLogin:false,
    userInfo:{}
  },
  getters: {
  },
  mutations: {
    login(state,data){
      if(data.token){
        window.localStorage.setItem('token',data.token);
        delete data.token;
      }
      window.localStorage.setItem('userInfo',JSON.stringify(data));
      state.userInfo = data;
      state.hasLogin = true;
    },
    outLogin(state){
      state.userInfo = {};
      state.hasLogin = false;
      window.localStorage.removeItem('token');
      window.localStorage.removeItem('userInfo');
    }
  },
  actions: {
  },
})
