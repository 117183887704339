<template>
  <div class="menu">
    <div
      @click="to(item)"
      v-for="(item, index) in menus"
      :key="index"
      :class="{ active: active == item.id }"
      class="item"
      v-if="!item.auth || userInfo.group == item.auth"
    >
      <i :class="item.icon"></i>
      {{ item.name }}
    </div>
  </div>
</template>
<script>
import menus from "./../data/menus.js";
import { mapState } from "vuex";
export default {
  data() {
    return {
      menus: menus,
      active: menus[0].id,
    };
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  mounted() {
    this.active = this.$route.name;
    setTimeout(() => {
      try {
        let name = menus.find((item) => item.id == this.active);
        this.$emit("change", name);
      } catch (error) {}
    }, 200);
  },
  watch: {
    $route() {
      this.active = this.$route.name;
    },
  },
  methods: {
    to(item) {
      if (this.$route.path != item.path) {
        this.$router.push({
          path: item.path,
        });
        this.$emit("change", {name:item.name,icon:item.icon});
      }
    },
  },
};
</script>
<style lang="less">
@color: rgb(45, 161, 255);
.menu {
  .item {
    display: block;
    padding: 20px;
    text-decoration: none;
    color: #444;
    cursor: pointer;
    font-size: 14px;
    &.active {
      color: @color;
    }
    &:hover {
      color: @color;
      background-color: #f1f1f1;
    }
  }
}
</style>