<template>
  <div id="app">
    <template v-if="hasLogin">
      <div class="container">
        <div class="left" v-if="!iframe">
          <div class="logo">新媒体数据</div>
          <div class="proinfo">
            <h2>欢迎您,{{ userInfo.username }}</h2>
            <div class="outlogin" @click="handlerOutLogin">退出登陆</div>
          </div>
          <div class="hr"></div>
          <Menu @change="(e) => (pageName = { ...pageName, ...e })" />
        </div>
        <div class="right">
          <div class="nav" :style="iframe ? 'padding:0 ;' : ''">
            <div style="padding-left: 10px">
              <i :class="pageName.icon"></i>
              {{ pageName.name }}
            </div>
            <div class="change-pass" v-if="!iframe">
              <el-button type="text" @click="dialogVisible = true"
                >修改密码</el-button
              >
            </div>
            <div class="change-pass" v-if="iframe">
              <el-button type="text" @click="openNewWindow"
                >新窗口打开</el-button
              >
            </div>
          </div>
          <div
            class="warp"
            :style="iframe ? 'padding:0;padding-top:10px;' : ''"
          >
            <keep-alive>
              <router-view></router-view>
            </keep-alive>
          </div>
        </div>
      </div>
      <el-dialog title="账号密码修改" :visible.sync="dialogVisible" width="30%">
        <el-form label-width="80px">
          <el-form-item label="用户名">
            <el-input v-model="userInfo.username" readonly></el-input>
          </el-form-item>
          <el-form-item label="登陆密码">
            <el-input
              v-model="password"
              show-password
              type="password"
              placeholder="6-16位密码"
            ></el-input>
          </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitForm()">保 存</el-button>
        </span>
      </el-dialog>
    </template>
    <template v-else>
      <Login />
    </template>
  </div>
</template>
<script>
// 获取url参数
function getQueryString(name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  var r = window.location.search.substr(1).match(reg);
  if (r != null) return unescape(r[2]);
  return null;
}
import { mapState, mapMutations } from "vuex";
import Menu from "./components/Menu.vue";
import menuData from "./data/menus";
import Login from "./components/Login.vue";
import { Decrypt } from "./plugins/secret";
export default {
  components: {
    Menu,
    Login,
  },
  computed: {
    ...mapState(["hasLogin", "userInfo"]),
  },
  data() {
    return {
      password: "",
      pageName: {
        icon: "el-icon-key",
        name: "账户数据查询",
      },
      dialogVisible: false,
      iframe: false,
    };
  },
  created() {
    this.code = getQueryString("code");
    // 如果进入首页时带有iframe参数就是框架请求
    if(getQueryString("iframe")){
      this.setIframe()
    }
    this.checkLogin();
  },
  methods: {
    ...mapMutations(["login", "outLogin"]),
    handlerOutLogin() {
      this.$confirm("确认退出登陆？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
      })
        .then((result) => {
          this.outLogin();
        })
        .catch((err) => {});
    },
    register(code) {
      // 做一个用户的注册
      this.$axios
        .post("login/register", {
          code,
        })
        .then((result) => {
          this.$message.success("预约系统授权登陆");
          // 直接登陆好了
          this.$store.commit("login", result.data);
        })
        .catch((err) => {
          // 如果没法解密用户的话就提示登陆
        });
    },
    // 检查登陆状态
    checkLogin() {
      this.$axios
        .get("login/islogin")
        .then((result) => {
          console.log(result);
          if (result.code == 0) {
            // 检查本地是否存储了用户信息
            let userInfo = window.localStorage.getItem("userInfo") || null;
            try {
              userInfo = JSON.parse(userInfo);
              if (!userInfo || typeof userInfo !== "object") throw new Error();
              this.$store.commit("login", userInfo);
            } catch (error) {
              this.$store.commit("outLogin");
            }
          } else {
            if (this.code) {
              // 如果code存在就对服务器请求用户信息
              this.register(this.code);
            }
          }
        })
        .catch((err) => {});
    },
    submitForm() {
      if (!this.password) {
        this.$message.error("密码不能为空!");
        return;
      }
      if (this.password.length < 6 || this.password.length > 16) {
        this.$message.error("密码长度请控制在6-16位");
        return;
      }
      this.$axios
        .post("admin/editpass", {
          id: this.userInfo.id,
          password: this.password,
        })
        .then((result) => {
          this.$message.success("密码修改成功,请重新登陆系统!");
          setTimeout(() => {
            this.dialogVisible = false;
            this.outLogin();
          }, 2000);
        })
        .catch((err) => {});
    },
    openNewWindow() {
      // 新窗口打开
      window.open(
        "http://" + window.location.host + this.$route.path,
        "_blank"
      );
    },
    setIframe() {
      this.iframe = true;
      // window.innerHeight = window.innerHeight;
      setTimeout(() => {
        var html = `
          <table class="leftmenu_1">
              <tbody>
                  <tr>
                      <td class="head">新媒体数据</td>
                  </tr>
                  `;
        menuData.forEach((item, index) => {
          if (item.auth && this.userInfo.group != item.auth) return;
          html += `<tr><td class="item" onmouseover="mi(this)" onmouseout="mo(this)"><a href="javascript:void(0);" onclick="iframeToPath(${index})" class="">${item.name}</a>
                      </td></tr>`;
        });
        // 对框架层添加菜单
        html += `
              </tbody>
          </table>`;
        window.parent.postMessage(html, "*");
      }, 500);
      // 接受跨域通讯
      window.addEventListener("message", (event) => {
        // if (event.origin !== 'http://media.gshtkq.com') return;
        // 从menu里面找到当前的索引
        if (menuData[event.data]) {
          let item = menuData[event.data];
          this.$router.push({
            path: item.path,
            query: {
              iframe: true,
            },
          });
          this.pageName = {
            name: item.name,
            icon: item.icon,
          };
        }
      });
    },
  },
};
</script>
<style lang="less">
@color: #409eff;
body {
  padding: 0;
  margin: 0;
  font-size: 14px;
  background-color: #f1f1f1;
}

#app {
  .container {
    .left {
      position: fixed;
      left: 0;
      top: 0;
      height: 100%;
      width: 160px;
      background-color: #fff;
      z-index: 1;

      .hr {
        background-color: #f1f1f1;
        height: 6px;
        width: 100%;
      }

      .logo {
        background-color: @color;
        color: #fff;
        font-size: 20px;
        height: 50px;
        text-align: center;
        line-height: 50px;
        position: relative;
      }

      .proinfo {
        padding: 20px;
        h2 {
          font-size: 15px;
          font-weight: normal;
          line-height: 1;
          margin: 0;
          margin-bottom: 10px;
        }
        .outlogin {
          font-size: 12px;
          color: #888;
        }
        .outlogin:hover {
          cursor: pointer;
          color: @color;
        }
      }
    }

    .right {
      .nav {
        background-color: #fff;
        height: 50px;
        line-height: 50px;
        font-size: 16px;
        padding-left: 175px;
        position: relative;
        .change-pass {
          position: absolute;
          right: 20px;
          top: 0;
        }
      }
      .warp {
        padding: 15px;
        padding-left: 175px;
        box-sizing: border-box;
      }
    }
  }
}
</style>
