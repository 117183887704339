import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import './common/global.less'
import store from './store'

Date.prototype.format = function (fmt) {
  var o = {
    "M+": this.getMonth() + 1, //月份
    "d+": this.getDate(), //日
    "h+": this.getHours(), //小时
    "m+": this.getMinutes(), //分
    "s+": this.getSeconds(), //秒
    "q+": Math.floor((this.getMonth() + 3) / 3), //季度
    S: this.getMilliseconds(), //毫秒
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (this.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }
  for (var k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
    }
  }
  return fmt;
};

window.toNumber = function(value){
  var newValue = value;
  if(typeof(value) === 'string'){
    newValue = Number(value);
  }
  return newValue != newValue ? 0 : newValue;
}

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


/**
 * Math添加精确加法
 */
 Math.plus  = function(){

  var _list = arguments,
      times = 1,
      result = 0;
  //先获取最大倍数
  var len = _list.length;
  for(var i=0;i<len;i++){
      var y = (_list[i]+'').split(".")[1];
      if(y && times < y.length){
          times = y.length;
      }
  }

  times = Math.pow(10,times) ;

  //整数计算
  for(var i=0;i<len;i++){

      result = (result + (_list[i] * times));

  }

  return result/times;

}
