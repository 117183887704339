import Vue from 'vue'
import VueRouter from 'vue-router'
import menus from '@/data/menus.js'

Vue.use(VueRouter)

const routes = menus.map(item => {
  return {
      path: item.path,
      name: item.id,
      component: () => import(`../views/${item.id}.vue`)
  } 
})

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
