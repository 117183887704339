export default [
    // {
    //     id:'Home',
    //     name:'首页',
    //     path:'/'
    // },
    {
        id:'Account',
        name:'账户数据查询',
        path:'/account',
        icon:'el-icon-key'
    },
    {
        id:'Create',
        name:'新增记录',
        path:'/create',
        icon:'el-icon-edit-outline'
    },
    {
        id:'Channel',
        name:'渠道到诊业绩',
        path:'/channel',
        icon:'el-icon-data-line'
    },
    {
        id:'Data',
        name:'综合数据查询',
        path:'/data',
        icon:'el-icon-search'
    },
    // {
    //     id:'Hospital',
    //     name:'医院业绩查询',
    //     path:'/hospital'
    // },
    {
        id:'Accountset',
        name:'账户设置',
        path:'/accountset',
        icon:'el-icon-key',
        // auth:'admin'
    },{
        id:'Channelset',
        name:'渠道设置',
        path:'/channelset',
        icon:'el-icon-box',
        // auth:'admin'
    },{
        id:'Hospitalset',
        name:'医院设置',
        path:'/hospitalset',
        icon:'el-icon-school',
        // auth:'admin'
    },{
        id:'Tablefield',
        name:'表字段匹配',
        path:'/tablefield',
        icon:'el-icon-connection',
        // auth:'admin'
    },{
        id:'Admin',
        name:'管理员列表',
        path:'/admin',
        icon:'el-icon-user',
        auth:'admin'
    },
    {
        id:'Log',
        name:'操作日志',
        path:'/log',
        icon:'el-icon-tickets'
    }
]