import Vue from 'vue'
import { 
    Button, 
    Table, 
    TableColumn, 
    Dialog, 
    Form, 
    FormItem, 
    Input, 
    Select,
    Option,
    DatePicker,
    Switch,
    Radio,
    RadioGroup,
    Checkbox,
    CheckboxGroup,
    MessageBox,
    Message,
    Row,
    Col,
    Pagination,
    Loading
 } from 'element-ui'

const elements = { 
    Button, 
    Table, 
    TableColumn, 
    Dialog, 
    Form, 
    FormItem, 
    Input, 
    Select,
    Option,
    DatePicker,
    Switch,
    Radio,
    RadioGroup,
    Checkbox,
    CheckboxGroup,
    Row,
    Col,
    Pagination,
    Loading
 };
for (const key in elements) {
    Vue.use(elements[key])
}

Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$message = Message;
Vue.prototype.$prompt = MessageBox.prompt;